import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import Cookies from "js-cookie";
import axios from "axios";
// import { symbol } from "prop-types";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import { useHistory } from "react-router-dom";
import { SwalSuccess ,SwalError } from "./";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Backdrop,CircularProgress,makeStyles} from "@material-ui/core";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import { FaInfoCircle,FaEdit} from "react-icons/fa";
import SweetAlert from "react-bootstrap-sweetalert";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const shadeMappings = {
    "none": { label: "No.SH", tooltip: "Shade: NONE" },
    "brown": { label: "BR", tooltip: "Shade: Brown" },
    "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
    "green": { label: "GR", tooltip: "Shade: Green" },
    "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
    "grey": { label: "GREY", tooltip: "Shade: Grey" },
    "black": { label: "BLK", tooltip: "Shade: Black" },
    "pink": { label: "PINK", tooltip: "Shade: Pink" },
    "blue": { label: "BLUE", tooltip: "Shade: Blue" },
    "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
};

const renderShadeLabel = (shade) => {
    const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
    if (!mapping) return null;

    return (
        <span>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
            >
                <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
            </OverlayTrigger>
        </span>
    );
};

export const ConfirmGoods = (props) => {
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = useState(false);
    const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    // const [refe,setRefe] = useState()
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // const [addRef,setAddRef] = useState(false)
    const [loading, setLoading] = React.useState(false);
    const [isSubmitting ,SetIsSubmitting] = useState(false)
    const [openLoader, setOpenLoader] = React.useState(false);
    const [ip,setIp] = React.useState()
    const [data , setData] =useState([])
    const [availabilitydata,setAvailabilityData] = useState([])
    const [totalourprice,setTotalOurPrice] = useState(0)
    const [totalourcarat,setTotalOurCarat] = useState(0)
    const [totalvalid,setTotalvalid] = useState(0)
    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        SetIsSubmitting(true)
    };
    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        SetIsSubmitting(false)
    };

    const history = useHistory()
    useEffect(() => {
        setData(props.diamondselected || [])
        SetComment(null)
        setShowConfirm(props.show)
        axios({
            method: 'GET',
            // url: "http://ip-api.com/json/?fields=status,country,region,regionName,city,district,zip,isp,mobile,proxy,hosting,query",
            url:"https://api.ipify.org",
            // headers: { 
            //     'Access-Control-Allow-Origin': '*', 
            //     'Content-Type': 'application/json'
            //   },
            })
            .then(function (res) {
                // setIpdata({query:res.data})
                if(res && res.data){
                    setIp(res.data)                    
                }
            })
            .catch((res) => {
            });
    },[props.show])
    useEffect(() => {
        if (props.diamondselected && props.diamondselected.length) {
            enableLoading()
            let availibilitycheck = []
            for (let i = 0; i < props.diamondselected.length; i++) {
                if (props.diamondselected[i]?.status_api_available?.toString() === "1") {
                    availibilitycheck.push({
                        sup_id: props.diamondselected[i]?.sup_id,
                        stoneno: props.diamondselected[i]?.loatNo,
                        diamond_type: props.diamondselected[i]?.diamondType,
                        doc_stock_id: props.diamondselected[i]?.stockID
                    })
                }
            }
            if (availibilitycheck.length) {
                let BASEURL = "https://api.staging.global.diamondsupload.com"
                if (process.env.REACT_APP_ENV === "production") {
                    BASEURL = "https://api.global.diamondsoncall.com"
                }
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${BASEURL}/supplier/getAvailibilityStatus`,
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
                    },
                    data: {
                        supplierdata: availibilitycheck
                    }
                };

                axios.request(config)
                    .then((response) => {
                        disableLoading()
                        if (response?.data?.data) {
                            setAvailabilityData(response?.data?.data)
                        }
                    })
                    .catch((error) => {
                        disableLoading()
                        console.log(error);
                    });
            }
            else{
                disableLoading()
            }
        }
    }, [props.diamondselected])
    useEffect(() => {
        if (data.length) {
            let totalprice = 0
            let totalcarat = 0
            let totalvalid = 0
            for (let i = 0; i < data.length; i++) {
                let available = true
                let checkavailable = availabilitydata.find(avvalue => avvalue?.stoneno?.toString() === data[i]?.loatNo?.toString())
                if (checkavailable?.status === "UnAvailable") {
                    available = false
                }
                if(available && data[i].location === "INDIA"){
                    totalvalid += 1
                    totalprice += data[i].ourPrice
                    totalcarat += data[i].carat
                }
            }
            setTotalOurPrice(totalprice)
            setTotalOurCarat(totalprice/totalcarat || 0)
            setTotalvalid(totalvalid)
        }
    }, [data, availabilitydata])
    const closeModel = () => {
        props.onHide()
        setShowConfirm(false)
    }
    // const closeSwal = (props) => {
    //     // props.onHide()
    //     setShowConfirm(true)
    //     setSwalSuccessOpen(false)
    // }
    const swalErrorOpenfn = (props) => {
        closeModel()
        setSwalErrorOpen(true)
    }
    
    const swalSuccessOpenfn = (props) => {
        closeModel()
        setSwalSuccessOpen(true)
    }
   
    const ConfirmDiamonds = (diamonds) => {
        // let dianondinfo = diamonds.map(value => {
        //     return {
        //         diamond_id:value.stockID,
        //         diamond_type:value.diamondType === "L"  ? value.diamondType:"N",
        //         Certi_NO:value.certificate,
        //         client_ref:value.client_ref
        //     }
        // })
        let dianondinfo = []
        for (let i = 0; i < diamonds.length; i++) {
            let available = true
            let checkavailable = availabilitydata.find(avvalue => avvalue?.stoneno?.toString() === diamonds[i]?.loatNo?.toString())
            if (checkavailable?.status === "UnAvailable") {
                available = false
            }
            if(available && diamonds[i].location === "INDIA"){
                dianondinfo.push({
                    diamond_id:diamonds[i].stockID,
                    diamond_type:diamonds[i].diamondType === "L"  ? diamonds[i].diamondType:"N",
                    Certi_NO:diamonds[i].certificate,
                    client_ref:diamonds[i].client_ref
                })
            }
        }
        const finalData = {
            "user_id":props.userinfo.id,
            "operation":"buy",
            "diamond_info":dianondinfo,
            "customer_comment":comment,
            "ip":ip
        }
        // console.log(finalData,"finalData")
        enableLoading()
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/diamondHoldOrBuyGlobal`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        }).then(res => {
            disableLoading()
            // console.log("12346",res.data.success)
            if (res.data.success === false){
                swalErrorOpenfn()
                setAlertMessage(res.data.message)
            }
            if(res && res.data && res.data.success){
                // console.log("1234",res.data)
                // swalSuccessOpenfn()
                // setSwalSuccessOpen(true)
                // alert(JSON.stringify(res.data.data))
                setAlertMessage(res.data.data[0].status)
                for(let i = 0 ; i < res.data.data.length;i++){
                    if(res.data.data[i].issuccess === true){
                        swalSuccessOpenfn()
                        reDirectToConfirm()
                    } else if (res.data.data[i].issuccess === false || res.data.data[i].ssuccess===false){
                        swalErrorOpenfn()
                    }
                }
                SetComment(null)
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }
    
    const handleInputChange = (event,buyDataIdData,index) => {
        const value = event.target.value;
        // console.log(value.length)
        setInputValue(value);
        // setRefe(value)
        if (value.length > 29) {
          setErrorMessage('String length should not exceed 30 characters.');
        } else {
          setErrorMessage('');
        }
        let buyDataId = [...buyDataIdData]
        buyDataId[index] = {...buyDataId[index],client_ref:event.target.value}
        // console.log(buyDataId,"buyDataId")
        setData(buyDataId)
        // console.log(buyDataId)
    };

    const AddReference = (buyDataIdData,index) => {
        let buyDataId = [...buyDataIdData]
        buyDataId[index] = {...buyDataId[index],addref:true}
        setData(buyDataId)
        // console.log(buyDataId,"buyDataId1212")
        
    }

    const reDirectToConfirm = () => {
        history.push({
            pathname: `/confirmgoods`
        })
    }
    const [comment,SetComment] = useState()
    // console.log(comment,"COMMENT")
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <div>
            <Modal
                show={showConfirm}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND}</Modal.Title>
                    <div className="d-flex">
                        <div className=" font-weight-bold font-size-lg d-flex mr-4">
                            {props.language.CONFIRM_GOODS_POPUP_INR_RATE}: <div className="ml-2">₹{data.length !== 0 ? JSON.stringify(data[0].curr_inr) : null}</div >
                        </div>
                        <button type="button" className="close" onClick={() => closeModel(props)}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-3">
                            <span>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND} : {props.diamondselected.length}</span>
                            <div>
                                {((props.diamondselected.length) - totalvalid ) == 0 ||  totalvalid == 0? "":
                                 <div className="d-flex font-weight-bold">
                                    <div className="text-success mr-2">{totalvalid} Diamonds are available</div> 
                                    <div className="text-danger">{((props.diamondselected.length) - totalvalid )} Diamonds are not available</div>
                                </div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div
                                options={perfectScrollbarOptions}
                                className="custom-scrollbar border-bottom"
                                style={{ maxHeight: "322px", position: "relative" }}
                            >
                                <table className="table border-bottom">
                                    <thead>
                                        <tr className="bg-gray-100 sticky-top">
                                            <th className="text-center py-3">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_DETAILS}</th>
                                            <th className="text-center py-3">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION} <br /> {props.language.CONFIRM_GOODS_POPUP_DIAMOND_SHIPPING_DAYS}</th>
                                            <th className="py-3">Advanced Tag</th>
                                            <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICEUSD} <br />
                                                USD/CT
                                            </th>
                                            <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICEINR} <br />
                                                INR/CT
                                            </th>
                                            <th className="text-center  py-3 align-middle">
                                                Client <br />
                                                Reference
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((value,index) => {
                                        let available = true
                                        let blurr = false
                                        let checkavailable = availabilitydata.find(avvalue => avvalue?.stoneno?.toString() === value?.loatNo?.toString())
                                        if(checkavailable?.status === "UnAvailable"){
                                            available = false
                                        }
                                        if(!available || value.location !== "INDIA"){
                                            blurr = true
                                        }
                                        return (
                                            <tr className={`position-relative ${(value.diamondType === "N" || value.diamondType === "W") && (value.treatment && value.treatment.toLowerCase() !== "none") ? 'bg-danger-o-20' : ''} ${blurr ? "cursor-na not-holdable-tr" : ""}`} key={index}>
                                                <td>
                                                    {!available ?  <span className="NotAvailable"></span> :  value.location !== "INDIA"?<span className="CanNotBuy"></span>:""}
                                                    {/* {value.can_hold === 1 ? "":  <span style={{fontSize:"8px",color:"red"}}> DIAMOND NOT AVAILABLE FOR HOLD</span> } */}
                                                    <div className={`d-flex ${blurr ? "CanNotHoldDiv" : "" }`}>
                                                        <div className="text-center p-0">
                                                            {value.image ? (
                                                                <img className="mr-2" alt={value.shape} src={value.image} width="50px" />
                                                            ) : (
                                                                <img className="mr-2" alt={value.shape} src={toAbsoluteUrl(`/media/shape/${value.shape.toLowerCase()}-no.png`)} onError={e => {
                                                                    e.currentTarget.src = toAbsoluteUrl(
                                                                        `/media/shape/noimage.jpg`
                                                                    )
                                                                }} width="50px" style={{ backgroundColor:"#f4f4f4", }} />
                                                            )}
                                                        </div>
                                                        <div className="pl-2 p-0">
                                                            <div className="mb-1">
                                                            <span>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                {value.availability === "Memo" ||
                                                                                value.availability === "Hold"
                                                                                    ? `Business Process`
                                                                                    : value.availability === "Guaranteed"
                                                                                    ? `Guaranteed`
                                                                                    : value.availability === "Not Available"
                                                                                    ? `Not Available`
                                                                                    : value.availability === "Subject to Prior sale"
                                                                                    ? `Subject to Prior sale`
                                                                                    : `Unknown`}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img
                                                                            className="img-fluid availIco mr-2"
                                                                            alt={value.availability}
                                                                            src={toAbsoluteUrl(
                                                                                `/media/svg/${
                                                                                    value.availability === "Memo" ||
                                                                                    value.availability === "Hold"
                                                                                        ? `availability_primary.svg`
                                                                                        : value.availability === "Guaranteed"
                                                                                        ? `availability_success.svg`
                                                                                        : value.availability === "Not Available"
                                                                                        ? `availability_danger.svg`
                                                                                        : value.availability ===
                                                                                        "Subject to Prior sale"
                                                                                        ? `availability_warning.svg`
                                                                                        : `availability_blank_gray.svg`
                                                                                }`
                                                                            )}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </span>
                                                                <span
                                                                    className={`${
                                                                        value.diamondType === "N" || value.diamondType === "W"
                                                                            ? value.isfancy ? "F" : "W"
                                                                            :    value.isfancy ? "LF" : "L"
                                                                    }_diamond mr-3 small`}
                                                                >
                                                                    {value.diamondType === "N" || value.diamondType === "W"
                                                                        ? value.isfancy ? "NF" : "N"
                                                                        : value.isfancy ? "LF" : "L" }
                                                                </span>
                                                                <span className="small mr-3">
                                                                    {value.diamondType === "N" || value.diamondType === "W" ? "N" : "L"}-{value.stockID}
                                                                </span>
                                                                <span className=" small">
                                                                    {value.lab}-
                                                                    {value.certificate}
                                                                </span>
                                                                
                                                            </div>
    
                                                            <span className="text-dark font-weight-bold d-block">
                                                                {value.shape}{" "}
                                                                {value.carat.toFixed(2)}{" "}
                                                                {value.color}{" "}
                                                                {value.clarity}{" "}
                                                                {value.cut}{" "}
                                                                {value.polish}{" "}
                                                                {value.symmetry}{" "}
                                                                {value.fluorescence}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`text-center ${blurr ? "CanNotHoldDiv" : "" }`}>
                                                    {value.location} <br />
                                                    {value.shippingDays} days
                                                </td> 
                                                <td className={`${blurr ? "CanNotHoldDiv" : "" }`}>
                                                    <div className="advTag" style={{ maxWidth: "150px" }}>
                                                        {/* shade */}
                                                        {renderShadeLabel(value.shade)}
                                                        
                                                        { value.milky && value.milky.toLowerCase() === "no milky" ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            NO MILKY
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
    
                                                        { value.milky && value.milky.toLowerCase() === "luster low" ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            Luster Low
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
    
                                                        { value.milky && value.milky.toLowerCase() === "light milky" ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            LIGHT MILKY
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
    
                                                        { value.milky && value.milky.toLowerCase() === "milky" ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            MILKY
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
    
                                                        {/* eyeclean */}
                                                        { value.eyeClean && value.eyeClean.toLowerCase() === "yes" ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            100% Eye Clean
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
                                                        { value.eyeClean && value.eyeClean.toLowerCase() === "no" ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            No Eye Clean
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
    
                                                        {/* growthtype */}
                                                        { value.diamondType === "L" && (value.growthType && value.growthType === "CVD") ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            CVD
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
                                                        { value.diamondType === "L" && (value.growthType && value.growthType === "HPHT") ?
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            HPHT
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        :
                                                            null
                                                        }
                                                        
                                                        { (value.diamondType === "L" ) && (value.treatment && value.treatment.toLowerCase() === "as grown") ? 
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            As Grown
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        : 
                                                            null
                                                        }
                                                        {/* natural treated */}
                                                        { (value.diamondType === "N" || value.diamondType === "W") && (value.treatment && value.treatment.toLowerCase() !== "none")  ? 
                                                            <span>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            Treated
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                                                </OverlayTrigger>
                                                            </span>
                                                        : 
                                                            null
                                                        }
                                                    </div>
                                                </td>
                                                <td className={`text-right ${blurr ? "CanNotHoldDiv" : "" }`}>
                                                    ${numberWithCommas(value.ourPrice)} <br />
                                                    <span className="text-muted small">$/CT {numberWithCommas(value.ourRate)}</span>
                                                </td>
                                                <td className={`text-right  ${blurr ? "CanNotHoldDiv" : "" }`}>
                                                    ₹{numberWithCommas(value.ourPrice * (data.length !== 0 ? data[0].curr_inr : "83.25"))} <br />
                                                    <span className="text-muted small">₹/CT {numberWithCommas(value.ourRate * (data.length !== 0 ? data[0].curr_inr : "83.25"))}</span>
                                                </td>
                                                <td className={`text-right align-middle ${blurr ? "CanNotHoldDiv" : "" }`}>
                                                    {value.addref  ? 
                                                        <input type="text" className="form-control" onChange={(e) => handleInputChange(e,data,index)} maxLength={30} placeholder="Please enter reference"/>
                                                    :
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                Click to add Reference
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button className="btn btn-icon btn-outline-primary btn-sm" onClick={()=> AddReference(data,index)}><FaEdit className="font-size-h5"/></button>
                                                        </OverlayTrigger>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <div className=" font-weight-bold mt-4 font-italic" >{props.diamondselected.length > 4 ? "Scroll for explore all diamonds":""}</div>
                            {errorMessage && <p className="mb-1 text-danger">{errorMessage}</p>}
                            <div className="row mt-5">
                                <div className="col-12 font-size-h6 text-right">
                                     INR/CT {props.language.CONFIRM_GOODS_POPUP_PRICE} : ₹{numberWithCommas(totalourcarat * (data.length !== 0 ? data[0].curr_inr : "83.25"))}
                                </div>
                                <div className="col-12 font-size-h6 mt-2 text-right font-weight-bold">
                                    {props.language.CONFIRM_GOODS_POPUP_TOTAL_INR} : ₹{numberWithCommas(totalourprice * (data.length !== 0 ? data[0].curr_inr : "83.25"))}
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12">
                                    
                                    <Form.Group controlId="Notes">
                                        <Form.Label>
                                            {props.language.CONFIRM_GOODS_POPUP_NOTES}:
                                        </Form.Label>
                                        <Form.Control as="textarea" rows="3" onChange={(e) => SetComment(e.target.value)} maxLength={256} placeholder="Please enter your notes if any"/>
                                    </Form.Group>
                                    <span className="p-2">255 Characters Remaining</span>
                                </div>
                            </div>
                            {/* <pre>{JSON.stringify(props)}</pre> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="mr-2 font-weight-bold" onClick={() => closeModel(props)}>
                        {props.language.CONFIRM_GOODS_POPUP_CLOSE}
                    </Button>
                    {totalvalid?<Button disabled={isSubmitting} className="px-8 font-weight-bold" variant="primary" onClick={() => ConfirmDiamonds(data)}>
                    {props.language.CONFIRM_GOODS_POPUP_CONFIRM_ORDER}
                        {loading && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop className={classes.backdrop} open={openLoader} >
                            <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
                        </Backdrop>
                    </Button>:""}
                </Modal.Footer>
            </Modal>
            <SwalSuccess show={swalSuccessOpen} message={alertMessage} onClose={() => setSwalSuccessOpen(false)} responseOnOk={reDirectToConfirm}/> 
            {/* <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} closeBtn={true}/>  */}
            { props.userinfo.kyc_status === "KYC_REJ" || props.userinfo.kyc_status === "NO_KYC"? (
            <>
                {swalErrorOpen && (
                    <SweetAlert
                        error
                        // title="Warning"
                        onConfirm={()=>history.push('/profile')}
                        confirmBtnText={props.userinfo.kyc_status === "NO_KYC" ? "Complete Profile" : "Revise Profile"}
                        confirmBtnBsStyle="primary"
                        showCancel
                        onCancel={() => setSwalErrorOpen(false)}
                        cancelBtnText="close"
                        cancelBtnBsStyle="secondary"
                    >
                       <span className="d-block mb-2 font-weight-bold">{alertMessage}</span>
                    </SweetAlert>
                )} 
            </>):(
                <>
                    {swalErrorOpen && (
                        <SweetAlert
                            error
                            // title="Warning"
                            onConfirm={() => setSwalErrorOpen(false)}
                            confirmBtnText="close"
                            confirmBtnBsStyle="primary"
                        >
                           <span className="d-block mb-2 font-weight-bold">{alertMessage}</span>
                        </SweetAlert>
                    )} 
                </>
            )}
        </div>
    );
};
